import {source} from "./imageUrls"
import {rolesPermissions} from "./permissions"

export const MenuList: any = [
    {
        id: "company-module",
        icon: source.menu.ChargerManagementIcon,
        name: "Company Management",
        path: "/company-management",
        child: [
            {
                id: "company-profile",
                icon: "",
                name: "Profile",
                path: "/company-management/profile",
            },
            {
                id: "registration",
                icon: "",
                name: "Companies",
                path: "/company-management/registration",
                submenu_path: "/company-management/registration/details",
            },
            {
                id: "mapping",
                icon: "",
                name: "Mapping",
                path: "/company-management/mapping",
            },
            {
                id: "admin",
                icon: "",
                name: "Admin",
                path: "/company-management/admin",
            },
            {
                id: "settlement",
                icon: "",
                name: "Settlements",
                path: "/company-management/settlements",
            },
        ],
    },
    {
        id: "location-module",
        icon: source.menu.LocationIcon,
        name: "Locations",
        path: "/locations",
        child: [
            {
                id: "station",
                icon: "",
                name: "Stations",
                path: "/locations/stations",
            },
            {
                id: "charger",
                icon: "",
                name: "Chargers",
                path: "/locations/chargers",
                submenu_path: "/locations/chargers/charger-profile",
            },
        ],
    },
    {
        id: "network-module",
        icon: source.menu.NetworkIcon,
        name: "Network",
        path: "/network",
    },
    {
        id: "tariff-module",
        icon: source.menu.TariffIcon,
        name: "Tariff",
        path: "/tariff",
    },
    {
        id: "token-module",
        icon: source.menu.TokenIcon,
        name: "Tokens",
        path: "/tokens",
    },
    {
        id: "session-module",
        icon: source.menu.SessionIcon,
        name: "Sessions",
        path: "/sessions",
        child: [
            {
                id: "session-module",
                icon: "",
                name: "Session Details",
                path: "/sessions/session-details",
            },
            {
                id: "session-module",
                icon: "",
                name: "Session Flow Timeline",
                path: "/sessions/session-timeline",
            },
        ],
    },
    {
        id: "log-module",
        icon: source.menu.LogsIcon,
        name: "Logs",
        path: "/logs",
    },
    {
        id: "complaint-module",
        icon: source.menu.ComplaintIcon,
        name: "Complaints",
        path: "/complaints",
    },
]

export const handleMenuList = (menu: any, userPermissions: any) => {
    const allPermissions = rolesPermissions
    const currentPermissions =
        userPermissions.length > 0 ? userPermissions.filter((item: any) => allPermissions.includes(item)) : {}

    const currentMenuPermissions = menu.reduce((acc: any, item: any) => {
        if (currentPermissions.length > 0 && currentPermissions.includes(item.id)) {
            const filteredItem = {...item}
            if (item.child) {
                filteredItem.child = handleMenuList(item.child, userPermissions)
                if (filteredItem.child.length === 0) {
                    delete filteredItem.child
                }
            }
            acc.push(filteredItem)
        }
        return acc
    }, [])

    return currentMenuPermissions
}
